import React, { useEffect } from "react"
import { navigate } from "@reach/router"

export default function NotFound({ data }) {
  console.log(data)

  useEffect(() => {
      navigate('/404/index.html');
  }, []);
  return (
    <div>
      <h1>404 - Page Not Found</h1>
    </div>
  )
}
